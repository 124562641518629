import React, { Component, Fragment } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Carousel from 'react-bootstrap/Carousel'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from 'react-bootstrap/Jumbotron'
import axios from 'axios'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import find from 'lodash/find'
import ReactImagesCarousel, { Modal, ModalGateway } from 'react-images'
import IconEnvelope from '../assets/icons/envelope-circled.svg'
import IconListCheck from '../assets/icons/list-check-circled.svg'
import IconClock from '../assets/icons/clock-circled.svg'

import './index.scss'
import Layout from '../components/Layout'
import LatestWork from '../components/LatestWork'
import { appendSuffixToPath, gallerySuffix, thumbnailSuffix } from '../utils/ImageVariantsHelper'
import { email_href_mailto_crypted, htmlBrandName } from '../constants'
import { linkTo_UnCryptMailto } from '../utils/unCryptMail'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import SEO from '../components/SEO'

class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.carouselRef = React.createRef()
    }

    state = {
        openGallery: false,
        currentPhotoSlug: null,
        albumContents: [
            /* After componentDidMount()-finished successfully, this will look like:
                {
                    "id": "aquarelle",
                    "title": "Aquarelle",
                    "images": [
                        {
                            "cardTitle": "18.09.2007_Cindy2",
                            "cardDescription": "Hier habe ich [] gemalt.",
                            "postDate": "2020-01-05",
                            "src": "content/pages/albums/Aquarelle/18.09.2007_Cindy2.jpg"
                        }
                    ]
                },
                {
                    "id": "farbstifte",
                    "title": "Farbstifte",
                    "images": []
                }
            */
        ]
    }

    closeGallery = () => {
        this.setState({
            openGallery: false,
            currentPhotoSlug: null,
        })
    }

    onPhotoPress = (slug) => {
        this.setState({
            openGallery: true,
            currentPhotoSlug: slug,
        })
    }

    toggleGallery = () => {
        this.setState(prevState => ({
            openGallery: !prevState.galleryOpened,
        }))
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)

        // retrieve all album content and move into `state`-variable: `state.content`
        axios.get('/api/albums/index')
            .then(response => {
                const albums = response.data.albums
                albums.forEach(album => {
                    axios.get(`/api/albums/${album.id}`)
                        .then(response => {
                            this.setState(prevState => ({
                                albumContents: prevState.albumContents.concat(response.data),
                            }))
                        })
                        .catch(error => {
                            // handle error
                            console.log(error)
                        })
                        .then(() => {
                            // always executed
                        })
                })
            })
            .catch(error => {
                // handle error
                console.log(error)
            })
            .then(() => {
                // always executed
            })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    getLatestWorkEntry = () => {
        const photos = this.state.albumContents.flatMap(albumContent => albumContent.images)
        const photosSortedByDateDesc = reverse(sortBy(photos, ['postDate']))

        return photosSortedByDateDesc.length > 0 ? photosSortedByDateDesc[0] : null
    }

    getLatestWorkInAlbum = (albumId) => {
        const photos = (find(this.state.albumContents, ['id', albumId]) || []).images
        const photosSortedByDateDesc = reverse(sortBy(photos, ['postDate']))
        return photosSortedByDateDesc.length > 0 ? photosSortedByDateDesc[0] : null
    }

    // TODO: Remove latest-work and components/resources for it
    renderLatestWork = () => {
        const latestWorkAlbumItemData = this.getLatestWorkEntry()
        return (
            latestWorkAlbumItemData ?
                <LatestWork className='py-3' albumItemData={latestWorkAlbumItemData} onPhotoPress={() => { this.onPhotoPress() }} /> :
                <Fragment />
        )
    }

    renderGallery = () => {
        const {
            openGallery
        } = this.state
        const latestWorkEntry = this.getLatestWorkEntry()

        return (
            latestWorkEntry ?
                <ModalGateway>
                    {openGallery ? (
                        <Modal onClose={this.closeGallery}>
                            <ReactImagesCarousel views={[
                                {
                                    caption: `${latestWorkEntry.cardTitle}${
                                        latestWorkEntry.cardDescription ? ' // ' + latestWorkEntry.cardDescription : ''
                                        }`,
                                    source: {
                                        download: latestWorkEntry.src,
                                        fullscreen: appendSuffixToPath(latestWorkEntry.src, gallerySuffix),
                                        regular: appendSuffixToPath(latestWorkEntry.src, gallerySuffix),
                                        thumbnail: appendSuffixToPath(latestWorkEntry.src, thumbnailSuffix)
                                    }
                                }
                            ]} />
                        </Modal>
                    ) : null}
                </ModalGateway> : <React.Fragment />
        )
    }

    // Use this for installing parallax effect…
    // handleScroll = (event) => {
    //     const element = this.carouselRef.current
    //     element.styles = { top: '0' }
    // }

    render() {
        const { data, location } = this.props

        return (
            <React.Fragment>
                <Layout location={location}>
                    <SEO 
                        skipTemplate
                        title='Tierportraits von TIERGETREU'
                        description='Dein Tier als Kunstwerk − mit handgezeichneten Tierportraits von TIERGETREU.
                        Eine individuelle Geschenkidee oder wundervolle Erinnerung an deinen Vierbeiner.'
                    />
                    <div className="landing-page">
                        <section className='landing-page-hero' ref={this.carouselRef}>
                            <Carousel>
                                {data.allHeroLandingPage.heroItems.map(({ heroItem }) => (
                                    <Carousel.Item key={heroItem.id}>
                                        <PreviewCompatibleImage
                                            className='d-block w-100 h-100'
                                            imageInfo={{ image: heroItem.image.localFile }}
                                            role='presentation'
                                            alt=''
                                            loading='eager'
                                            fadeIn={false}
                                            {/* Outcomment this to enable lookup of latest artwork.
                                            src={
                                                this.getLatestWorkInAlbum('aquarelle') ?
                                                    this.getLatestWorkInAlbum('aquarelle').src
                                                    : ''
                                            } */...{}}
                                        />
                                        <Carousel.Caption>
                                            <div
                                                className='title'
                                                dangerouslySetInnerHTML={{ __html: heroItem.text.childMarkdownRemark.html }}
                                            />
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </section>
                        <div className='landing-page-content bg-white'>
                            <Jumbotron className='bg-logo mb-5'>
                                <h1>Dein Tier als Kunstwerk!</h1>
                                <p>
                                    Könntest du auch einfach dahinschmelzen, wenn dich dein Lieblingsvierbeiner mit
                                    großen, treuen Augen anschaut? Hast du dich schon oft gefragt, wie du dieses
                                    atemberaubende Gefühl zum Ausdruck bringen kannst?
                                </p>
                                <p className="emphasize">
                                    Für dich erschaffe ich ein einzigartiges, handgemaltes Portrait deines treuen
                                    Vierbeiners!
                                </p>
                                <p>
                                    Sieh selbst und lass dich von meinen Arbeiten inspirieren!
                                </p>
                            </Jumbotron>

                            <section className='offering offering-inquiry bg-tint-muted text-white'>
                                <Container>
                                    <Row>
                                        <Col className='col-jumbotron' xs={12} md={6} lg={5}>
                                            <h2 className='offering-heading'>Dein Tierportrait. <span className='text-tint-secondary text-nowrap'>Hund, Katze oder Pferd?</span></h2>
                                            <p className='mb-5'>Nicht nur Hunde können einem ans Herz wachsen. Du denkst an deine Katze oder dein Pferd? Kein Problem. Dem Motiv sind keine Grenzen gesetzt.</p>
                                            <Button as={Link} to='/anfragen' variant='secondary'>Portrait anfragen</Button>
                                        </Col>
                                        <Col className='col-image' xs={12} md={6} lg={7}>
                                            <div className='image-wrapper'>
                                                <picture>
                                                    <source media="(max-width: 991px)" srcSet={data.imageOfferingInquiryMobile.localFile.childImageSharp.fluid.srcSet} />
                                                    <source media="(min-width: 992px)" srcSet={data.imageOfferingInquiryDesktop.localFile.childImageSharp.fluid.srcSet} />
                                                    <img
                                                        src={data.imageOfferingInquiryMobile.localFile.childImageSharp.fluid.src}
                                                        loading='lazy'
                                                        role='presentation'
                                                        alt=''
                                                    />
                                                </picture>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>

                            <section className='offering offering-how-to bg-tint-muted text-white'>
                                <Container>
                                    <h2 className='offering-heading'>So einfach geht's! <br /><span
                                        className='text-tint-secondary'>Dein eigenes Kunstwerk.</span>
                                    </h2>
                                    <Row className='steps'>
                                        <Col className='step' xs={12} md={4}>
                                            <div className='icon'><IconEnvelope role='presentation' alt='' /></div>
                                            <div className='title'>1. Schreib mir eine Nachricht!</div>
                                        </Col>
                                        <Col className='step' xs={12} md={4}>
                                            <div className='icon'><IconListCheck role='presentation' alt='' /></div>
                                            <div className='title'>2. Wir klären deine Wünsche.</div>
                                        </Col>
                                        <Col className='step' xs={12} md={4}>
                                            <div className='icon'><IconClock role='presentation' alt='' /></div>
                                            <div className='title'>3. Mit Liebe erstelle ich dein Tierportrait.</div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>

                            <section className='offering offering-freeby bg-tint-muted text-white'>
                                <Container>
                                    <Row>
                                        <Col className='col-jumbotron' xs={12} md={6} lg={5}>
                                            <h2 className='offering-heading'>Noch unsicher? <span className='text-tint-secondary text-nowrap'>Deine Skizze – gratis!</span></h2>
                                            <p className=''>Ich fertige für dich eine Skizze von deinem Liebling an. Absolut kostenlos! Die Skizze kannst du dann zu Hause einfach ausdrucken.</p>
                                            <p className='mb-5'>Hol dir deine Gratis-Skizze!</p>
                                            <Button as={Link} to='/gratis-skizze' variant='secondary'>Jetzt zur Gratis-Skizze</Button>
                                        </Col>
                                        <Col className='col-image' xs={12} md={6} lg={7}>
                                            <Img fluid={data.imageOfferingFreebyMobile.localFile.childImageSharp.fluid} />
                                        </Col>
                                    </Row>
                                </Container>
                            </section>

                            <section className='art-styles'>
                                <Container>
                                    <h2 className='art-styles--heading'>Meine Maltechniken</h2>
                                    {/* Three columns of text below the carousel */}
                                    <Row>
                                        <Col lg={4}>
                                            <PreviewCompatibleImage
                                                className='rounded-circle digital'
                                                imageInfo={{ image: data.imageDigital.localFile }}
                                                alt='' role='presentation'
                                            />
                                            <h3>Digital</h3>
                                            <p>Eine digitale Illustration so flexibel wie du! Jedes Format kann nach Belieben erstellt werden.</p>
                                            <Button as={Link} to='/digital' variant='primary'>weiter stöbern</Button>
                                            <Link className='link-desktop-only' to='/digital' />
                                        </Col>
                                        <Col lg={4}>
                                            <PreviewCompatibleImage
                                                className='rounded-circle aquarelle'
                                                imageInfo={{ image: data.imageAquarelle.localFile }}
                                                alt='' role='presentation'
                                            />
                                            <h3>Aquarelle</h3>
                                            <p>Besonders schöne Effekte durch feine Schattierungen und weiche Farbverläufe.</p>
                                            <Button as={Link} to='/aquarelle' variant='primary'>weiter stöbern</Button>
                                            <Link className='link-desktop-only' to='/aquarelle' />
                                        </Col>
                                        <Col lg={4}>
                                            <PreviewCompatibleImage
                                                className='rounded-circle farbstifte'
                                                imageInfo={{ image: data.imageFarbstifte.localFile }}
                                                alt='' role='presentation'
                                            />
                                            <h3>Farbstifte</h3>
                                            <p>Sehr feine Linien erhöhen den Detailgrad. Die Farben besitzen einen leichten Glanz.</p>
                                            <Button as={Link} to='/farbstifte' variant='primary'>weiter stöbern</Button>
                                            <Link className='link-desktop-only' to='/farbstifte' />
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                            <section className='offering offering-product bg-tint-muted text-white mb-5'>
                                <Container>
                                    <Row>
                                        <Col className='col-jumbotron' xs={12} md={{ span: 5, order: 2 }} lg={{ span: 5, order: 2 }}>
                                            <h2 className='offering-heading'>Du bist der größte Fan deines Tieres? <span className='text-tint-secondary'>Zeig es!</span></h2>
                                            <p className='mb-5'>Individuell gestaltete Tassen und T-Shirts mit handgefertigter digitaler Illustration deines Vierbeiners.</p>
                                            <Button as={Link} to='/shop' variant='secondary'>Zum Shop</Button>
                                        </Col>
                                        <Col className='col-image' xs={12} md={{ span: 7, order: 1 }} lg={{ span: 7, order: 1 }}>
                                        <div className='image-wrapper'>
                                            <picture>
                                                <source media="(max-width: 991px)" srcSet={data.imageOfferingProductMobile.localFile.childImageSharp.fluid.srcSet} />
                                                <source media="(min-width: 992px)" srcSet={data.imageOfferingProductDesktop.localFile.childImageSharp.fluid.srcSet} />
                                                <img
                                                    src={data.imageOfferingProductMobile.localFile.childImageSharp.fluid.src}
                                                        loading='lazy'
                                                    role='presentation'
                                                    alt=''
                                                />
                                            </picture>
                                        </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                            <section className='marketing'>
                                <Container>
                                    <Row className='author'>
                                        <Col className='col-jumbotron' xs={{ order: 2, span: 12 }} md={{ order: 1, span: 6 }}>
                                            <h2 className='marketing-heading mb-4'>Die Künstlerin Kira. <br /><span className='text-tint'>Ein großes ❤ für Tiere.</span></h2>
                                            <p className='text-muted'>
                                                Ich bin Kira – Hundenärrin und Malerin mit Herz. Ich freue mich, dir
                                                auf {htmlBrandName} meine Arbeiten zu präsentieren.<br />
                                            </p>
                                            <p>
                                                Du willst mehr über mich wissen?<br /><br />
                                                <Button as={Link} to="/about" variant="primary">Meine Geschichte</Button>
                                            </p>
                                        </Col>
                                        <Col className='col-image' xs={{ order: 1, span: 12 }} md={{ order: 2, span: 6 }}>
                                            <PreviewCompatibleImage
                                                imageInfo={{ image: data.landingPage.marketingAuthorImage.localFile }}
                                                imgStyle={{ objectFit: 'contain' }}
                                                placeholderImgStyle={{ objectFit: 'contain' }}
                                            />
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className='contact'>
                                        <Col className='col-jumbotron' xs={{ order: 2, span: 12 }} md={{ order: 2, span: 6 }}>
                                            <h2 className='marketing-heading mb-4'>Kontakt. <span className='text-tint'>Und so erreichst du mich.</span></h2>
                                            <p className='text-muted'>
                                                Du interessierst dich für ein eigenes Portrait deines treuen Vierbeiners
                                                oder hast Fragen zu einem Auftrag?<br />
                                            </p>
                                            <p>Schreibe mir eine Nachricht, ich komme schnellstmöglich auf dich zurück!</p>
                                            <p>
                                                <i className='far fa-envelope' />&nbsp;
                                            <button
                                                    className='link'
                                                    onClick={() => { linkTo_UnCryptMailto(email_href_mailto_crypted) }} >
                                                    hallotiergetreu [at] gmail [punkt] com
                                            </button>
                                            </p>
                                        </Col>
                                        <Col className='col-image' xs={{ order: 1, span: 12 }} md={{ order: 1, span: 6 }}>
                                            <PreviewCompatibleImage
                                                imageInfo={{ image: data.landingPage.marketingContactImage.localFile }}
                                                imgStyle={{ objectFit: 'contain' }}
                                                placeholderImgStyle={{ objectFit: 'contain' }}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </div>
                    </div>
                </Layout>
                {this.renderGallery()}
            </React.Fragment>
        )
    }
}

export default LandingPage
export const pageQuery = graphql`
  query LandingPageQuery {
    landingPage: contentfulLandingPage {
        marketingAuthorImage {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 500, cropFocus: CENTER) {
                        ...GatsbyImageSharpFluid
                    }
                }
                publicURL
            }
        }
        marketingContactImage {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 500, cropFocus: CENTER) {
                        ...GatsbyImageSharpFluid
                    }
                }
                publicURL
            }
        }
    }
    allHeroLandingPage: allContentfulHeroLandingPage(
        sort: { fields: [order], order: ASC }
    ) {
        heroItems: edges {
            heroItem: node {
                id
                order
                text {
                    childMarkdownRemark {
                        html
                    }
                }
                image {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1000, maxHeight: 512, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        publicURL
                    }
                }
            }
        }
    }
    imageAquarelle: contentfulAsset(contentful_id: {eq: "4L07SiAZGw5dohPyqgVGWP"}) {
        title
        localFile {
            childImageSharp {
                fixed(width: 140, height: 140) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
    imageFarbstifte: contentfulAsset(contentful_id: {eq: "4PkupymWA99uZDje2cTVFf"}) {
        title
        localFile {
            childImageSharp {
                fixed(width: 140, height: 140) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
    imageDigital: contentfulAsset(contentful_id: {eq: "7Lj9OF7dJKesOaCYnVqAUJ"}) {
        title
        localFile {
            childImageSharp {
                fixed(width: 140, height: 140) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
    imageOfferingInquiryMobile: contentfulAsset(title: {eq: "Portraitanfrage portrait"}) {
        title
        localFile {
            childImageSharp {
                fluid(maxWidth: 450, pngQuality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                    src
                }
            }
            publicURL
        }
    }
    imageOfferingInquiryDesktop: contentfulAsset(title: {eq: "Portraitanfrage landscape"}) {
        title
        localFile {
            childImageSharp {
                fluid(maxWidth: 650, pngQuality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                    src
                }
            }
            publicURL
        }
    }
    imageOfferingFreebyMobile: contentfulAsset(contentful_id: { eq: "2jH9UxrlZrHuwbbt3oCRLH" }) {
        title
        localFile {
            childImageSharp {
                fluid(maxWidth: 650, pngQuality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                    src
                }
            }
            publicURL
        }
    }
    imageOfferingProductMobile: contentfulAsset(title: {eq: "Produkte portrait"}) {
        title
        localFile {
            childImageSharp {
                fluid(maxWidth: 450, pngQuality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                    src
                }
            }
            publicURL
        }
    }
    imageOfferingProductDesktop: contentfulAsset(title: {eq: "Produkte landscape"}) {
        title
        localFile {
            childImageSharp {
                fluid(maxWidth: 650, pngQuality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                    src
                }
            }
            publicURL
        }
    }
  }
`
