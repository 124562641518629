import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import moment from 'moment'
import 'moment/locale/de'
import AlbumItem from './AlbumItem'
import { getStandardizedDate } from '../utils/DateHelper'

class LatestWork extends Component {
    getRelativeTimeString = () => {
        const postDate = getStandardizedDate(this.props.albumItemData.postDate)
        moment.locale('de')
        return moment(postDate).fromNow()
    }

    render() {
        const { className, albumItemData, onPhotoPress } = this.props
        return (
            <Container fluid className={classNames('latest-work bg-tint text-light', className)}>
                <Row noGutters>
                    <Col md={2} />
                    <Col xs={12} md={'auto'} className={'align-baseline mb-2'}>
                        <div className='lead w-100 float-left'>Aktuelle Arbeit</div>
                        <span className={'text-light-muted w-100'}>
                            <i className='far fa-clock' /> {this.getRelativeTimeString()}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col />
                    <Col xs={12} md={8} className='latest-work-image-container'>
                        <AlbumItem 
                            key={albumItemData.src}
                            className='bg-tint-secondary'
                            cardImageSrc={albumItemData.src}
                            cardTitle={albumItemData.cardTitle}
                            cardDescription={albumItemData.cardDescription}
                            onPress={onPhotoPress}
                        />
                    </Col>
                    <Col />
                </Row>
            </Container>
        )
    }
}

LatestWork.propTypes = {
    className: PropTypes.string,
    albumItemData: PropTypes.object.isRequired,
    onPress: PropTypes.func,
}

export default LatestWork
